function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        const context = this;
        clearTimeout(timeoutId); // Clear the previous timer
        timeoutId = setTimeout(() => {
            func.apply(context, args); // Call the function with the correct context and arguments
        }, delay);
    };
}
let hideinFocusParentsChildHidden = function () {
    if ($('.DynamicContentSection.inFocus').length === 0) {
        setTimeout(function () {
            $('.inFocusList  ').each(function () {
                var $contentDynamic = $(this);

                var $cards = $contentDynamic.find('.inFocusContent--SingleCard');
                var allCardsHidden = true;

                $cards.each(function () {
                    if ($(this).is(":visible")) {
                        allCardsHidden = false;
                        return false;  // Exit early if any card is visible
                    }
                });

                // If all cards are hidden, perform the action
                if (allCardsHidden) {
                    //   $(".relatedArticle").parents("div.section.vspace-sm").addClass('d-none');
                    //    $(".relatedArticleTitle").parents("div.section.vspace-sm").addClass('d-none');
                    //} else {
                    $contentDynamic.parents(".inFocus ").addClass('d-none');


                } else {
                    $contentDynamic.parents(".inFocus ").removeClass('d-none');

                }
            });
        }, 100);
    }
};
hideinFocusParentsChildHidden();
   $('button.Tags__Button, button.tag_Menu_Button').click(function () {
    //    //setTimeout(function (e) {
    //    //    equal_height_cards();
       //    //}, 200);
       $(".inFocusList ").parents(".inFocus ").removeClass('d-none');
        
       setTimeout(function () {
           hideinFocusParentsChildHidden();
       }, 200);
    });


// Function to show only the first three visible cards
function showFirstThreeCards() {
    const allCards = document.querySelectorAll('.inFocusList.equal-card .inFocusContent--SingleCard');
    let shownCount = 0;

    // Show only the first three visible cards (if fewer than 3, include 'All')
    allCards.forEach((card) => {
        if (card.classList.contains('visible')) {
            if (shownCount < 3) {
                card.classList.remove('hidden');
                card.style.display = 'flex';  // Ensure it's displayed
                shownCount++;
            } else {
                card.classList.add('hidden');
                card.style.display = 'none'; // Hide the card completely
            }
        } else {
            card.classList.add('hidden');
            card.style.display = 'none'; // Hide if not visible
        }
    });
}

// Function to filter the cards based on the active filter
function applyFilter(filterId) {
    const allCards = document.querySelectorAll('.inFocusList.equal-card .inFocusContent--SingleCard');
    let visibleCount = 0;

    // Apply filter and mark cards as visible or not based on the filter
    allCards.forEach(card => {
        if (filterId === 'all' || filterId === "All" || card.classList.contains(filterId)) {
            card.classList.add('visible');
            visibleCount++;
        } else {
            card.classList.remove('visible');
        }
    });

    // Show the 'All' card when fewer than 3 visible cards after applying the filter
    const commonCards = document.querySelectorAll('.inFocusList.equal-card .inFocusContent--SingleCard.All');

    if (filterId === 'all') {
        // Always show the 'All' card when 'all' filter is applied
        commonCards.forEach(commonCard => {
            commonCard.classList.add('visible');
            commonCard.style.display = 'flex'; // Ensure the 'All' card is shown
        });
    } else {
        // If fewer than 3 visible cards, show the 'All' card to complete the first 3 visible cards
        if (visibleCount < 3) {
            commonCards.forEach(commonCard => {
                commonCard.classList.add('visible');
                commonCard.style.display = 'flex'; // Ensure the 'All' card is shown
                visibleCount++; // Increment the visible count to include 'All'
            });
        } else {
            // Hide 'All' card if there are enough visible cards
            commonCards.forEach(commonCard => {
                commonCard.classList.remove('visible');
                commonCard.style.display = 'none'; // Hide the 'All' card
            });
        }
    }

    // After filtering, show only the first three visible cards (including 'All' card if needed)
    showFirstThreeCards();
}

// Load the filter from localStorage on page load
const savedFilter = localStorage.getItem('activeFilter') || 'all'; // Default to 'all' if no filter is saved
applyFilter(savedFilter); // Apply the saved filter on page load

// Event listener for filter button clicks
document.querySelectorAll('.tag_Menu_Button, .Tags__Button').forEach(button => {
    button.addEventListener('click', function () {
        const filterId = this.id;

        // Save the selected filter in localStorage
        localStorage.setItem('activeFilter', filterId);

        // Apply the selected filter
        applyFilter(filterId);
    });
});

jQuery(function ($) {
    //add here if any new tag option is added dynamically
    const filterClasses = ['Pharmacy', 'HealthCareProfessionals', 'NursingHome'];
    function setupFilterSync(filter1, filter2) {
        $(filter1 + ' .tag_Menu_Button, ' + filter2 + ' .Tags__Button').on('click', function () {
            var buttonId = $(this).attr("id");
            hideEmptyMenuTagFilter();
            localStorage.clear();
            localStorage.setItem("activeFilter", buttonId);
            filterContent(buttonId);
            synchronizeActiveState(filter1, filter2, buttonId);
        });
    }
    function synchronizeActiveState(filter1, filter2, activeId) {
        $(filter1 + ' .tag_Menu_Button').removeClass("activeTagFiter");
        $(filter2 + ' .Tags__Button').removeClass("activeTagFiter");

        $(filter1 + ' .tag_Menu_Button#' + activeId).addClass("activeTagFiter");
        $(filter2 + ' .Tags__Button#' + activeId).addClass("activeTagFiter");
    }

    function filterContent(activeId) {
        $('.Pharmacy, .HealthCareProfessionals, .NursingHome').hide();
        if (activeId === 'all' || activeId === 'All') {
            $('.Pharmacy, .HealthCareProfessionals, .NursingHome, .All').show();
            $('.hideNonSelectedFilter').removeClass('hideNonSelectedFilter');
        } else {
            $('.megaMenuInnerLink, .megaMenu_title, .dropdown__link').each(function () {
                var $element = $(this);
                var parent = $element.parents('.megaMenuDropdown, li');
                var hasFilterClass = filterClasses.some(function (filterClass) {
                    return $element.hasClass(filterClass);
                });
                if (hasFilterClass) {
                    parent.addClass('hideNonSelectedFilter');
                    if ($element.hasClass(activeId)) {
                        $element.show();
                        parent.removeClass('hideNonSelectedFilter');
                    }
                }
            });
            $('.' + activeId).show();
        }
        hideEmptyMenuTagFilter();
    }

    setupFilterSync('.filter1', '.filter2');
    var savedFilter = localStorage.getItem("activeFilter") || 'all';
    synchronizeActiveState('.filter1', '.filter2', savedFilter);
    filterContent(savedFilter);
});

// tag group filter border move animation
jQuery(function ($) {
    var menu = $(".tag_Button_Container");
    var indicator = $('<span class="indicator"></span>');
    menu.append(indicator);
    position_indicator(menu.find(".Tags__Button.activeTagFiter"));
    indicator[0].offsetHeight;
    indicator.css("opacity", 1); // Fade in
    $('.mainInterest').find("button").on('click', function () {
        position_indicator(menu.find(".Tags__Button.activeTagFiter"));
    })
    menu.find("button").on('click', function () {
        position_indicator($(this));
    });
    $(window).on('resize', function () {
        position_indicatorNoanimation(menu.find(".Tags__Button.activeTagFiter"));
    })

    function position_indicator(ele) {
        const mediaQueryMob = matchMedia('(max-width: 1118px)');
        if (mediaQueryMob.matches) {
            var left = ele.offset().left;
        } else {
            var left = ele.offset().left;
        }
        var width = ele.width();
        indicator.stop().animate({
            left: left,
            width: width
        });
    }

    function position_indicatorNoanimation(ele) {
        const mediaQueryMob = matchMedia('(max-width: 1118px)');
        if (mediaQueryMob.matches) {
            var left = ele.offset().left;
        } else {
            var left = ele.offset().left;
        }
        var width = ele.width();
        indicator.stop().css({
            left: left,
            width: width
        });
    }

    function updateFade() {
        var $scrollContainer = $('.filter2 .scroll_container');
        var scrollLeft = $scrollContainer.scrollLeft();
        var scrollWidth = $scrollContainer.get(0).scrollWidth;
        var containerWidth = $scrollContainer.outerWidth();

        // Update fade visibility
        $('.fade-left').toggle(scrollLeft > 0);
        $('.fade-right').toggle(scrollLeft + containerWidth < scrollWidth);
    }
    updateFade();


    $('.filter2 .scroll_container').on('scroll', function () {
        const mediaQuery = matchMedia('(min-width: 1118px)');
        if (!mediaQuery.matches) {
            updateFade();
        }
        position_indicator(menu.find(".Tags__Button.activeTagFiter"));
    });




    // Scroll to active button aftr page load based on the active class
    function scrollToActiveButton() {
        $('.Tags__Button').each(function () {
            if ($(this).hasClass('activeTagFiter')) {
                const $activeButton = $(this);
                const $scrollContainer = $('.scroll_container');
                const containerWidth = $scrollContainer.outerWidth();
                const targetOffset = $activeButton.position().left + $scrollContainer.scrollLeft();
                if (targetOffset < $scrollContainer.scrollLeft() + containerWidth / 2) {
                    $scrollContainer.animate({ scrollLeft: 0 }, 'slow', updateFade);
                } else {
                    $scrollContainer.animate({ scrollLeft: $scrollContainer.get(0).scrollWidth - containerWidth }, 'slow', updateFade);
                }
            }
        });
    }

    scrollToActiveButton();

    // Scroll to button when clicked
    $('.Tags__Button').on('click', function () {

        var $this = $(this);
        var $scrollContainer = $('.scroll_container');
        var containerWidth = $scrollContainer.outerWidth();
        var targetOffset = $this.position().left + $scrollContainer.scrollLeft();

        // Check if the button is on the left or right side
        if (targetOffset < $scrollContainer.scrollLeft() + containerWidth / 2) {
            $scrollContainer.animate({ scrollLeft: 0 }, 'slow', updateFade);
        } else {
            $scrollContainer.animate({ scrollLeft: $scrollContainer.get(0).scrollWidth - containerWidth }, 'slow', updateFade);
        }
    });
});

jQuery(function ($) {

    hideEmptyMenuTagFilter();
})
//hide main menu if tag filter group is not present
let hideEmptyMenuTagFilter = function () {
    $('.megaMenuFirstSection').each(function () {
        var $this = $(this);
        var $megaMenuDropdown = $this.find('.megaMenuDropdown');
        $megaMenuDropdown.each(function () {
            if ($(this).find('li').filter('.hideNonSelectedFilter').length === $(this).find('li').length) {
                $(this).find('li').parents('.content').parents('.megaMenuDropdown').addClass('hideNonSelectedFilter');
            } else {
                $(this).find('li').parents('.content').parents('.megaMenuDropdown').removeClass('hideNonSelectedFilter');
            }
            // });
        })
        if ($megaMenuDropdown.length === 0) {
            return;
        }
        if ($megaMenuDropdown.filter('.hideNonSelectedFilter').length === $megaMenuDropdown.length) {
            $this.parents('.nav .dropdown__item').addClass('hideNonSelectedFilter');
            if ($this.parents('.dropdown__item').hasClass('show-dropdown')) {
                removeStyle();

            }
        } else {
            $this.parents('.nav .dropdown__item').removeClass('hideNonSelectedFilter');
        }
    });
}
